import MuiMarkdown from "mui-markdown";
import React, {memo} from "react";
import {Box, Chip, Typography} from "@mui/material";
import { BountyResponse } from "interfaces/responses/Bounty";

interface BountyDetailChipsProps {
    bounty?: BountyResponse
}

function BountyDescription({bounty}: BountyDetailChipsProps) {
    return (
        <Box sx={{wordBreak: 'break-all'}}>
            <MuiMarkdown>{bounty?.description}</MuiMarkdown>
            {bounty?.technologies?.length ?
                <Box marginTop="2rem">
                    <Typography variant='subtitle3'>Technologies</Typography>
                    <Box>
                        {bounty.technologies.map((technology) =>
                            <Chip
                                variant='outlined'
                                sx={{mr: 1}}
                                key={technology.technology.title}
                                label={technology.technology.title.toUpperCase()}/>
                        )}
                    </Box>
                </Box> : <></>}
        </Box>
    )

}

export default memo(BountyDescription)