import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';


export default function CustomLinearProgress() {

  return (
    <Box className="loading-overlap" display="flex" justifyContent="center" alignItems="center" flexDirection="column" minHeight="60vh" >
      <Box alignItems="flex-start">
        <Typography variant="subtitle3" color={(theme) => theme.palette.secondary?.contrastText}>LOADING...</Typography>
        <LinearProgress />
      </Box>
    </Box>
  );
}
