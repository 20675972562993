import * as React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import ChevronRightSharpIcon from '@mui/icons-material/ChevronRightSharp';
import {BountyResponse} from '../../../interfaces/responses/Bounty';
import {useNavigate} from "react-router-dom";
import CompanyLogo from "../../../components/CompanyLogo";
import {THEME_COLORS} from "../../../constants/colors";
import BountyDetailsChips from "./BountyDetailChips";

import {amountWithCurrency} from "../../../utils/currency";
import BountyMainInfoCard from 'pages/BountyDetail/components/BountyMainInfoCard';

interface BountyProps {
    bounty: BountyResponse;
}

export default function BountyCard(props: BountyProps) {
    const {bounty} = props
    const navigation = useNavigate();


    const bountyDetail = () => {
        navigation(`/bounty/${bounty.id}`)
    }

    return (
        <Grid item key={bounty.id} onClick={bountyDetail} sx={{cursor: 'pointer'}} >
            <Paper sx={{pt: "56px", pb: "24px", px: 5, margin: 'auto', background: THEME_COLORS.background}}>
                <BountyMainInfoCard bounty={bounty}/>
                <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end" onClick={bountyDetail}>
                    <Typography sx={{mt: 2}} variant="body2">
                        More
                    </Typography>
                    <ChevronRightSharpIcon sx={{color: (theme) => theme.palette?.secondary?.main}}/>
                </Grid>
            </Paper>
        </Grid>
    );
}