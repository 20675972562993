import { Chip } from "@mui/material";
import React from "react";

interface ChipProps {
    label: string
    icon: React.ReactElement
}

export default function Chips({label, icon}: ChipProps) {
    return (
            <Chip sx={{minWidth: 130, m: 1, borderRadius: "4px"}} icon={icon} variant="outlined" label={label}/>
    )

}