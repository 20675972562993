export const THEME_COLORS = {
    primary: '#42A180',
    secondary: '#81CFB8',
    secondaryVariant: '#2D394D',

    success: '#11845B',
    review: '#DC952B',
    info: '#086CD9',
    error: '#DC2B2B',

    background: '#F1F2F3',
    surface: '#F7F7F8',
    backgroundControls: '#EDF8F5',
    disabled: '#9CA7A5',

    onPrimary: '#F1F2F3',
    onSecondary: '#F1F2F3',
    onSecondaryVariant: '#F1F2F3',

    contentLeading: '#005B3B',
    contentSupporting: '#003422',
    contentAccessory: '#576C67',
    surfaceDivider: '#9CA7A5',
    border: '#D0E1DD'
}