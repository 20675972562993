import React from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import Login from "./pages/Login";
import {NavLayout} from "./utils/ProtectedRoute";
import Bounties from "./pages/Bounties";
import CreateApplication from "./pages/Application";
import BountyDetail from "./pages/BountyDetail";
import ApplicationDetails from "./pages/ApplicationDetails";
import MyCandidates from "./pages/MyCandidates";
import ScrollToTop from "./utils/ScrollToTop";
import FormSuccessful from "pages/FormSuccessful";


function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/login" element={<NavLayout children={<Login/>} isProtected={false}/>} />
                <Route path="/bounties" element={<NavLayout children={<Bounties/>} />} />
                <Route path="/my-candidates" element={<NavLayout children={<MyCandidates/>} />} />
                <Route path="/application-form/:token" element={<NavLayout children={<CreateApplication/>} isProtected={false}/>}  />
                <Route path="/form-successful" element={<NavLayout children={<FormSuccessful/>} /> } />
                <Route path="/application/:applicationId" element={<NavLayout children={<ApplicationDetails/>} />}  />
                <Route path="/bounty/:bountyId" element={<NavLayout children={<BountyDetail/>} />} />
                <Route path="*" element={<Navigate to="/bounties" replace/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
