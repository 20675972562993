import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import React from "react";
import {Grid} from "@mui/material";
import Chips from "../../../components/Chips";
import {bountyState} from "../../../redux/slices/bounty";
import {BountyApplicationsResponse, BountyResponse} from "../../../interfaces/responses/Bounty";
import {salaryRange} from "../../../utils/currency";

interface BountyDetailChipsProps {
    bounty?: BountyResponse | BountyApplicationsResponse
}

export default function BountyDetailsChips({bounty}: BountyDetailChipsProps) {
    return (
        <Grid container direction="row" justifyContent='center'>
            {bounty?.location ? <Chips icon={<LanguageOutlinedIcon color="primary"/>} label={bounty.location} key={'location'}/> : <></>}
            {bounty?.employment_types?.map((employment_types, i) =>
                <Chips icon={<HomeOutlinedIcon color="primary"/>} label={employment_types.employment_type.type} key={`employment_type ${i}`}/> )}
            {bounty?.salary_range_start && bounty.salary_range_end ?
                <Chips icon={<MonetizationOnOutlinedIcon color="primary"/>} label={salaryRange(bounty.salary_range_start, bounty.salary_range_end)} key={'salaryRange'}/> : <></>}
            {bounty?.employment_arrangements?.map((arrangement, i) =>
                <Chips icon={<QueryBuilderOutlinedIcon color="primary"/>} label={arrangement.employment_arrangement.arrangement} key={`employment_arrangement ${i}`}/>)}
            {bounty?.helping_with_relocate ? <Chips icon={<FmdGoodOutlinedIcon color="primary"/>} label="relocation" key={'relocation'}/> : <>{bounty?.helping_with_relocate}</>}
        </Grid>
    )
}