export const amountWithCurrency = (amount: number | string) => {
    const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });

    // @ts-ignore
    return formatter.format(amount)
}

export const convertSalaryToK = (salary: string) => {
    let salaryNum = parseFloat(salary);
    if (!isNaN(salaryNum) && salaryNum >= 1000) {
        return String(amountWithCurrency(salaryNum/1000)).substring(1) + "k";
    } else {
        return salaryNum.toFixed(0);
    }
}

export const salaryRange = (salaryStart: string, salaryEnd: string) => {
    return convertSalaryToK(salaryStart) + '-' + convertSalaryToK(salaryEnd)
}

