import React from "react";
import {THEME_COLORS} from "../constants/colors";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import {Box, Icon, InputAdornment, TextField} from "@mui/material";
import {APPLICATION_RESUME_ALLOWED_EXTENSION} from "../constants/common";


interface DragDropProps {
    file: File | null
    setFile: (file: File) => void
}

export default function DragDrop({file, setFile}: DragDropProps) {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const target = e.currentTarget as HTMLInputElement;
        if (!e.target.files || e.target.files.length === 0) {
            return
        }
        setFile(target.files![0])
    };
    return (
        <Box position='relative'>
            <TextField
                value={file ? file.name : ''}
                label='Drag to upload your resume'
                required
                sx={{background: THEME_COLORS.surface, position: "absolute", width: "100%", textOverflow: 'ellipsis'}}/>
            <Icon sx={{zIndex: 10, position: 'absolute', top: '30%', right: '10px'}}>
                <CloudUploadOutlinedIcon color="primary"/>
            </Icon>
            <TextField type='file' fullWidth onChange={handleChange}
                       sx={{opacity: 0, z: 1}} inputProps={{accept: APPLICATION_RESUME_ALLOWED_EXTENSION}}/>
        </Box>
    )
}
