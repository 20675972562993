import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {TechnologyResponse} from "../../interfaces/responses/Technology";

export type TechnologyState = {
    technologies?: TechnologyResponse[]
};

const initialState: TechnologyState = { technologies: undefined};

const technologySlice = createSlice({
  name: "technologies",
  initialState,
  reducers: {
    setTechnologies(
      state: TechnologyState,
      action: PayloadAction<TechnologyResponse[]>
    ) {
      state.technologies = action.payload
    },
  },
});

export default technologySlice;