import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
    BountyArrangement,
    BountyEmploymentTypes,
    BountyResponse,
    BountyTechnology
} from "../../interfaces/responses/Bounty";
import {JobPositionResponse} from "../../interfaces/responses/JobPosition";
import {CompanyEmployeerResponse} from "../../interfaces/responses/CompanyUser";
import {CompanyResponse} from "../../interfaces/responses/Company";

export type bountyState = {
    bounty: BountyResponse | null
};

const initialState: bountyState = {
    bounty: null
}

const bountySlice = createSlice({
    name: "bounty",
    initialState,
    reducers: {
        setBounty(
            state: bountyState,
            action: PayloadAction<BountyResponse>
        ) {
            state.bounty = action.payload
        },
    }
});

export default bountySlice;