import React, {useState} from "react";
import {Grid, InputAdornment, TextField, Typography} from "@mui/material";
import {THEME_COLORS} from "../../../constants/colors";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import {UseFormRegister} from "react-hook-form";
import {CreateApplicationRequest} from "../../../interfaces/requests/CreateAplication";

interface TargetCompensationRangeProps {
    register: UseFormRegister<CreateApplicationRequest>
}

export default function TargetCompensationRange({register}: TargetCompensationRangeProps) {
    const [fromRange, setFromRange] = useState(0)
    const [toRange, setToRange] = useState(0)
    const [showRange, setShowRange] = useState(false)

    return (
        <>
            <TextField sx={{mt: 2, background: THEME_COLORS.surface}}
                       fullWidth
                       autoComplete='off'
                       label='Target Compensation Range'
                       value={!!fromRange || !!toRange ? `${fromRange} - ${toRange}` : ''}
                       InputLabelProps={{shrink: !!fromRange || !!toRange}}
                       onClick={() => {
                           setShowRange(!showRange)
                       }}
                       InputProps={{
                           sx: {'z-index': 10},
                           endAdornment: (showRange ?
                               <InputAdornment position='end'>
                                   <ArrowDropUpIcon color='primary'/>
                               </InputAdornment> :
                               <InputAdornment position='end'>
                                   <ArrowDropDownIcon color='primary'/>
                               </InputAdornment>),
                       }}

            />
            {showRange ?
                <Grid container display='flex' justifyContent='space-between' sx={{mt: 2}}>
                        <TextField
                            {...register('target_compensation_from',
                                {
                                    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                        setFromRange(Number(e.currentTarget.value))
                                    }
                                })}
                            InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: (fromRange ? <InputAdornment position="start"><Typography
                                    sx={{color: THEME_COLORS.secondaryVariant}}>$</Typography></InputAdornment> : <></>),
                            }}
                            placeholder='from  $  00.00' type='number' sx={{background: THEME_COLORS.surface, width: "47%"}}
                        />
                        <TextField
                            {...register('target_compensation_to',
                                {
                                    onChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                        setToRange(Number(e.currentTarget.value))
                                    }
                                })}
                            InputProps={{
                                inputProps: { min: 0 },
                                startAdornment: (toRange ? <InputAdornment position="start"><Typography
                                    sx={{color: THEME_COLORS.secondaryVariant}}>$</Typography></InputAdornment> : <></>),
                            }}
                            placeholder='to  $  00.00' type='number' sx={{background: THEME_COLORS.surface, width: '47%'}}/>
                </Grid>
                : <></>}
        </>
    )
}