export const API_URL = process.env.REACT_APP_API_URL

export const SUCCESS_CODE = 200
export const ERROR_CODE = 400

export const ALERT_DURATION = 3000

export interface AlertMessageType {
    message: string | string[]
    code: number
}

export const APPLICATION_RESUME_ALLOWED_EXTENSION = ".pdf, .doc, .docx, .heic, .heif, .jpeg, .png, .jpg"

export const MEDIUM_PAGE_SIZE = 900