import { Typography, Link, Chip, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { THEME_COLORS } from "../../constants/colors";
import store, { RootState } from '../../redux/store'
import BountyDescription from "../Bounties/components/BountyDescription";
import BountyMainInfoCard from "../BountyDetail/components/BountyMainInfoCard";
import { receiveApplicationDetails } from "./actions/ApplicationDetailsActions";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ApplicationStatus, UserRole } from "constants/enums";
import axiosService from "requests/axios";
import { salaryRange } from "utils/currency";
import CustomLinearProgress from "components/CustomLinearProgress";


export default function ApplicationDetails() {
    const {applicationId} = useParams()

    const { dispatch } = store
    const { application, loading } = useSelector((state: RootState) => state.applicationDetails);
    const [disabledButton, setDisabledButton] = useState(false)
    const auth = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate()


    const [showMore, setShowMore] = useState(false);
    
    useEffect(() => {
        if (applicationId) {
            dispatch(receiveApplicationDetails(applicationId))
        }
    }, [])

    const onDeclineOrSubmit = (action: string) => {
        setDisabledButton(true);
        axiosService.post(`/application/${action}/${applicationId}/`).then((res) => {
            setDisabledButton(false);
            navigate('/my-candidates')
        })
            .catch((err) => {
                setDisabledButton(false);
            });
    }

    useEffect(() => {
        if (application?.status == ApplicationStatus.COMPANY_REJECTED || application?.status == ApplicationStatus.HIRED){
            setDisabledButton(true)
        }
    }, [application])

    

    return ( 
        <>
            {!loading && application ? 
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                >
                <Grid item xs={3} style={{ width: "1200px", backgroundColor: THEME_COLORS.surface, padding: "56px", marginBottom: "200px"}}>
                    {<BountyMainInfoCard bounty={application?.bounty!}/>}
                    <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end"  onClick={() => setShowMore(!showMore)}>
                        <Typography sx={{mt: 2}} variant="subtitle3" color={(theme) => theme.palette?.primary?.dark}>
                            {showMore ? "LESS" : "MORE"}
                        </Typography>
                         { showMore ? <ExpandLessIcon fontSize="large" sx={{color: (theme) => theme.palette?.secondary?.main, paddingTop: "4px"}}/> : 
                         <ExpandMoreIcon fontSize="large" sx={{color: (theme) => theme.palette?.secondary?.main,  paddingTop: "4px"}}/>}
                    </Grid>                        
                    {showMore && <BountyDescription bounty={application.bounty}/>}

                    <Typography variant="subtitle1" color={(theme) => theme.palette?.secondary.dark} style={{marginTop: "90px"}}>CANDIDATE</Typography>
                    <Grid container alignItems="center">
                        <Grid item xs={6}>
                            <Typography variant="h4" component="div" color={(theme) => theme.palette?.secondary.dark}>
                             {application.first_name}  {application.last_name}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} container justifyContent="flex-end">
                            {application?.linkedin && <Link variant="subtitle2" href={application?.linkedin} target="_blank" sx={{ mx: 1 }}>
                            linkedin
                            </Link>}
                            <Link variant="subtitle2" href={application?.resume} target="_blank" sx={{ mx: 1 }}>
                            resume
                            </Link>
                        </Grid>
                    </Grid>
                    <Typography variant="body1" style={{marginBottom: "20px"}} color={(theme) => theme.palette?.secondary.dark}>{application.email}</Typography>
                    { auth?.account?.role == UserRole.RECRUITER && application?.target_compensation_from && application?.target_compensation_to && 
                    <>
                    <Typography variant="subtitle3" component="div" style={{marginTop: "24px"}}>Target Compensation Range</Typography>
                    <Typography variant="body2" color={(theme) => theme.palette?.primary.dark}>{salaryRange(application.target_compensation_from, application.target_compensation_to)}</Typography>
                    </>}
                    {application?.cover_note && <>
                    <Typography variant="subtitle3" component="div" style={{marginTop: "24px"}}>Cover Note</Typography>
                    <Typography variant="body2" color={(theme) => theme.palette?.primary.dark}>{application.cover_note}</Typography>
                    </>}
                    {application?.technologies.length > 0 && 
                    <>
                        <Box>
                            <Typography variant='subtitle3'>Technologies</Typography>
                            <Box>
                                {application?.technologies.map((technology) =>
                                    <Chip
                                        variant='outlined'
                                        key={technology.technology.title}
                                        label={technology.technology.title.toUpperCase()}/>
                                    )}
                                </Box>
                            </Box>
                        </>}
                        <Grid display="flex" justifyContent="center" style={{marginTop: "90px"}}>
                            <Button variant="outlined" disabled={disabledButton} style={{margin: "0 20px", padding: "24px 40px"}}  onClick={() => onDeclineOrSubmit('reject')}>
                                {auth.account?.role === UserRole.RECRUITER ? "DECLINE" : "REJECT"}
                            </Button>
                            <Button variant="contained" disabled={disabledButton} style={{margin: "0 20px", padding: "24px 40px"}}  onClick={() => onDeclineOrSubmit('confirm')}>
                                {auth.account?.role === UserRole.RECRUITER ? "APPROVE" : "HIRE"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid> : <CustomLinearProgress/>
            } 
        </>
    )
}
