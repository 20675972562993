import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import CompanyLogo from "../../../components/CompanyLogo";
import {THEME_COLORS} from "../../../constants/colors";
import {BountyApplicationsResponse, BountyResponse} from "../../../interfaces/responses/Bounty";
import BountyDetailsChips from "../../Bounties/components/BountyDetailChips";
import {amountWithCurrency} from "../../../utils/currency";


interface BountyMainInfoProps {
    bounty: BountyResponse | BountyApplicationsResponse;
}

const BountyMainInfoCard: React.FC<BountyMainInfoProps> = ({bounty}) => {
    return (
        <Box>
            <Grid container alignItems='flex-end' direction='row' gap={3} sx={{mb: 3}}>
                <Grid alignSelf='flex-start'>
                    <CompanyLogo company={bounty.company}/>
                </Grid>
                <Grid container sx={{height: 'min-content', flex: 1, justifyContent: 'space-between'}}
                      direction='column'>
                    <Typography variant="subtitle2" sx={{height: 'min-content'}}>
                        {bounty.company?.legal_name}
                    </Typography>
                    {bounty.company?.description && <Typography variant="body2" color={THEME_COLORS.surfaceDivider}>
                        {bounty.company.description}
                    </Typography>}
                    <Grid container justifyContent="space-between" alignItems="center" sx={{height: 'min-content'}}>
                        <Typography variant="subtitle1"
                                    sx={{fontWeight: 500, color: THEME_COLORS.contentLeading, height: 'min-content'}}>
                            {bounty.position?.title}
                        </Typography>
                        <Typography variant="subtitle2"
                                    sx={{color: THEME_COLORS.contentLeading, height: 'min-content',}}>
                            {amountWithCurrency(bounty.reward)} BOUNTY
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container width='fit-content' alignItems='flex-start'>
                <BountyDetailsChips bounty={bounty}/>
            </Grid>
        </Box>
    )
}

export default BountyMainInfoCard;