import React, {useEffect, useState} from 'react'
import {useSelector} from "react-redux";
import {Navigate, useNavigate} from "react-router-dom";
import {RootState} from "../redux/store";
import ToolBar from 'components/ToolBar';
import { API_URL, AlertMessageType, SUCCESS_CODE } from 'constants/common';
import axios from 'axios';
import { Box } from '@mui/material';
import AlertMessage from 'components/AlertMessage';


interface Props {
  children: JSX.Element,
  isProtected?: boolean,
};

export function NavLayout ({children, isProtected=true}: Props): JSX.Element {
    const auth = useSelector((state: RootState) => state.auth);
    let navigate = useNavigate();
    const [message, setMessage] = useState<AlertMessageType | null>(null);
    
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code')
        const company_id = urlParams.get('state')

        if (code && company_id) {
            axios.post(`${API_URL}/slack-integration/`, { code: code, state: company_id })
            .then(function (response) {
                setMessage({message: "Slack has been successfully integrated into your system", code: SUCCESS_CODE})
            })
            .catch((err) => {
                setMessage({message: err.response.data.message, code: err.response.data.status_code});
            });
        }

        if (isProtected && !auth.account) {
          navigate("/login");
        }

    }, [])


    return (
      <>
        <Box>{message && <AlertMessage setMessage={setMessage} message={message.message} code={message.code}/>}</Box> 
        <ToolBar/>
        {children}
      </>
    )
}

