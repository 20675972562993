import React, {useEffect, useState} from 'react';
import { Box, IconButton, InputAdornment, Typography, useTheme, Button, TextField, Grid } from '@mui/material';
import authSlice from "../../redux/slices/auth";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import axiosService from "../../requests/axios";
import {RootState} from "../../redux/store";
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import { AlertMessageType, ERROR_CODE} from '../../constants/common';
import AlertMessage from '../../components/AlertMessage';


export default function LoginIndex() {
    const [message, setMessage] = useState<AlertMessageType | null>(null);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const auth = useSelector((state: RootState) => state.auth);

    const theme = useTheme()
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    useEffect(() => {
        if (auth.account) {
            navigate("/bounties")
        }
    })

    const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);
        axiosService.post("auth/login/", {email, password})
            .then((res) => {
                dispatch(
                    authSlice.actions.setAuthTokens({
                        token: res.data.access,
                        refreshToken: res.data.refresh,
                    })
                );
                dispatch(authSlice.actions.setAccount(res.data.user));
                setLoading(false);
                navigate("/bounties");
            })
            .catch((err) => {
                setLoading(false);
                setMessage({message: "Wrong email or password!", code: ERROR_CODE})
            });
    };

    const submitDisabled = () => {
        return loading ? true : !(email && password) ? true : false
    }

    return (
        <>
        <Box>{message && <AlertMessage setMessage={setMessage} message={message?.message} code={message?.code}/>}</Box>
        <Grid container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '70vh' }}>
          <Grid item>
            <Grid container
              direction="column"
              alignItems="center"
              justifyContent="center"
              style={{
                backgroundColor: theme.palette.primary?.contrastText,
                border: '1px solid',
                borderColor: theme.palette.secondary?.light,
                padding: '48px 32px',
                boxShadow: "0px 2px 12px rgba(37, 143, 106, 0.07)",
                borderRadius: "8px",
              }}>
              <Typography variant="h4" sx={{marginBottom: "24px", color: (thema) => thema.palette.secondary?.dark}}>Login</Typography>
              <form onSubmit={handleLogin} autoComplete="no">
                <Grid item>
                  <TextField
                    label="Email"
                    name="email"
                    required
                    type="email"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ width: '420px' }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label="Password"
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    variant="outlined"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    style={{ marginTop: '20px', width: '420px' }}
                    InputProps={{
                      endAdornment: <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          sx={{ color: (theme) => theme.palette?.secondary?.main }}
                        >
                          {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                        </IconButton>
                      </InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    size="medium"
                    disabled={submitDisabled()}
                    style={{ marginTop: '20px', width: "420px" }}
                  >
                    Login
                  </Button>
                </Grid>
              </form>
            </Grid>
          </Grid>
        </Grid>
        </>
      );      
}