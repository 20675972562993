import * as React from 'react';
import BountyCard from './BountyCard';
import {Box, Grid} from '@mui/material';
import { BountyResponse } from '../../../interfaces/responses/Bounty';

interface BountiesProps {
    bounties: BountyResponse[];
  }

export default function BountiesList(props: BountiesProps) {
    const { bounties } = props
    return (
        <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center">
            {bounties.map((bounty: BountyResponse) => <Box width={'80%'} sx={{my: 2}} key={bounty.id}><BountyCard bounty={bounty}/></Box>)}
        </Grid>
    );
}