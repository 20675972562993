import { ApplicationDetailsResponse } from "../../../interfaces/responses/ApplicationDetailsResponse"
import {
    GET_APPLICATION_DETAILS_REQUEST,
    GET_APPLICATION_DETAILS_SUCCESS,
    GET_APPLICATION_DETAILS_FAILURE,
} from "../const"
import { ApplicationDetailsAction } from "../types"
import axiosService from "../../../requests/axios"
import { API_URL } from "../../../constants/common"
import { Dispatch } from "@reduxjs/toolkit"


const request = (): ApplicationDetailsAction => ({type: GET_APPLICATION_DETAILS_REQUEST})

const success = (application: ApplicationDetailsResponse): ApplicationDetailsAction => ({
    type: GET_APPLICATION_DETAILS_SUCCESS,
    payload: application,
})

const failure = (): ApplicationDetailsAction => ({type: GET_APPLICATION_DETAILS_FAILURE})


export const receiveApplicationDetails = (id: string) => async (dispatch: Dispatch<ApplicationDetailsAction>) => {
    dispatch(request())
    try {
        const {data: receiveApplicationDetailsData} = await axiosService.get(`${API_URL}/application/${id}/`)
        dispatch(success(receiveApplicationDetailsData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
