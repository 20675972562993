import React, {useEffect,} from 'react';
import {useSelector} from 'react-redux';
import store, {RootState} from '../../redux/store'
import InfiniteScroll from "react-infinite-scroll-component";
import StyledCircularProgress from '../../components/StyledCircularProgress';
import {receiveBountiesApplications, receiveNextPageBountiesApplications} from "./actions/BountiesApplicationsActions";
import {Box, Grid} from "@mui/material";
import {BountyApplicationsResponse} from "../../interfaces/responses/Bounty";
import BountyApplicationsCard from "./components/BountyApplicationsCard";


export default function MyCandidates() {
    const {dispatch} = store
    const {total, bounties, tableLoading, next} = useSelector((state: RootState) => state.bountiesApplications);

    useEffect(() => {
        dispatch(receiveBountiesApplications())
    }, [])

    const getNextBounties = () => {
        if (next !== null) {
            dispatch(receiveNextPageBountiesApplications(next))
        }
    }
    return (
        <>
            <InfiniteScroll next={getNextBounties} hasMore={!tableLoading && bounties.length < total}
                            loader={<StyledCircularProgress/>} dataLength={bounties.length}>
                {tableLoading ?
                    <StyledCircularProgress/> :
                    <Grid container justifyContent="center" alignItems="center">
                        {bounties.map((bounty: BountyApplicationsResponse) => <Box width={"80%"} key={`BountyApplicationsCard ${bounty.id}`}><BountyApplicationsCard bounty={bounty}/></Box>)}
                    </Grid>
                }
            </InfiniteScroll>
        </> 
    );
}