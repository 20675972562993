import {combineReducers} from "redux";
import authSlice from "./slices/auth";
import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import applicationFormSlice from "./slices/applicationForm";
import technologySlice from "./slices/technology";
import bountiesReducer from "../pages/Bounties/reducers/BountiesReducer";
import bountySlice from "./slices/bounty";
import applicationDetailsReducer from "../pages/ApplicationDetails/reducers/ApplicationDetailsReducer";
import bountiesApplicationsReducer from "../pages/MyCandidates/reducers/BountiesApplicationsReducer";


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth']
}

export const rootReducer = combineReducers({
    auth: authSlice.reducer,
    applicationForm: applicationFormSlice.reducer,
    technology: technologySlice.reducer,
    bounties: bountiesReducer,
    bountiesApplications: bountiesApplicationsReducer,
    bounty: bountySlice.reducer,
    applicationDetails: applicationDetailsReducer,
});

export const persistedReducer = persistReducer(persistConfig, rootReducer);