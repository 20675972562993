import { Box, Typography } from '@mui/material';
import React from 'react';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { useLocation } from 'react-router-dom';


export default function FormSuccessful() {
  const { state } = useLocation();
  const { recruiterFullName } = state || "";

  return (
    <Box display="flex" justifyContent="center" padding="0.625rem" alignItems="center" flexDirection="column" minHeight="60vh">
      <Box display="flex" flexDirection="column" alignItems="center">
        <CheckCircleOutlineOutlinedIcon sx={{color: (theme) => theme.palette.secondary?.main, width: "9.375rem", height: "9.375rem"}}/>
        <Typography variant="subtitle2" mt="2rem" textAlign="center" color={(theme) => theme.palette.secondary?.dark}>Thank you for filling out your information!</Typography>
        <Typography variant="body1" mt="1.5rem" textAlign="center" color={(theme) => theme.palette.primary?.dark}>
          {`Your application has been sent to ${recruiterFullName} to review. You will hear back from them soon!`}
        </Typography>
      </Box>
    </Box>
  );
}