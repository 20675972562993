import BountiesList from './components/BountiesList';
import ToolBar from '../../components/ToolBar';
import React, { useEffect, } from 'react';
import { useSelector } from 'react-redux';
import { receiveBounties, receiveNextPageBounties } from './actions/BountiesActions';
import store, { RootState } from '../../redux/store'
import InfiniteScroll from "react-infinite-scroll-component";
import StyledCircularProgress from '../../components/StyledCircularProgress';


export default function BountiesIndex() {
  const { dispatch } = store
  const { total, bounties, tableLoading, next } = useSelector((state: RootState) => state.bounties);

  useEffect(() => {
    dispatch(receiveBounties())
  }, [])

  const getNextBounties = () => {
    if (next !== null){
      dispatch(receiveNextPageBounties(next))
    }
  }

  return (
    <>
      <InfiniteScroll next={getNextBounties} hasMore={!tableLoading && bounties.length < total}
        loader={<StyledCircularProgress/>} dataLength={bounties.length}>
        {tableLoading ? 
          <StyledCircularProgress/> :
            <BountiesList bounties={bounties}/> 
        }
      </InfiniteScroll>
    </>
  );
}