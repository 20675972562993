import React from "react";
import { Box, CircularProgress } from "@mui/material";

const StyledCircularProgress: React.FC = () => {
  return (
    <Box
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginBottom: "50px"
      }}
    >
      <CircularProgress style={{ color: "inherit" }} />
    </Box>
  );
};

export default StyledCircularProgress;
