import React from "react";
import {ApplicationLiteResponse} from "../../../interfaces/responses/Applications";
import {Box, Button, Grid, Typography} from "@mui/material";
import {THEME_COLORS} from "../../../constants/colors";
import {salaryRange} from "../../../utils/currency";
import {daysAgo} from "../../../utils/time";
import {useSelector} from "react-redux";
import {RootState} from "../../../redux/store";
import {ApplicationStatus, UserRole} from "../../../constants/enums";
import {useNavigate} from "react-router-dom";

interface ApplicationTabProps {
    application: ApplicationLiteResponse
}


export default function ApplicationTab({application}: ApplicationTabProps) {
    const auth = useSelector((state: RootState) => state.auth);
    const navigate = useNavigate()



    const applicationCreateAt = () => {
        const days = daysAgo(application.created_at)
        switch (days) {
            case 0:
                return 'today'
            case 1:
                return 'yesterday'
            default:
                return days + ' days ago'
        }
    }

    const ViewApplicationButton = (
        <Button
            onClick={() => navigate(`/application/${application.id}`)}
            size='small'
            sx={{color: THEME_COLORS.secondaryVariant, border: `2px solid ${THEME_COLORS.secondaryVariant}`, px: 3}}>
            view application
        </Button>)
    const showStatus = () => {
        switch (application.status) {
            case ApplicationStatus.RECRUITER_REJECTED:
                return <Typography variant='subtitle3' sx={{color: THEME_COLORS.error}}>declined</Typography>
            case ApplicationStatus.COMPANY_REVIEWING:
                return auth.account?.role === UserRole.RECRUITER
                    ? <Typography variant='subtitle3' sx={{color: THEME_COLORS.review}}>reviewing by company</Typography> : ViewApplicationButton
            case ApplicationStatus.COMPANY_REJECTED:
                return <Typography variant='subtitle3' sx={{color: THEME_COLORS.error}}>{auth.account?.role == UserRole.EMPLOYER ? "rejected" : "rejected by company"}</Typography>
            case ApplicationStatus.HIRED:
                return <Typography variant='subtitle3' sx={{color: THEME_COLORS.success}}>hired</Typography>
            default:
                return ViewApplicationButton
        }
    }

    const applicationCompensationRange = (application.target_compensation_from && application.target_compensation_to)
        ? salaryRange(application.target_compensation_from, application.target_compensation_to) : null

    return (
        <Grid sx={{borderRadius: '4px', border: '1px solid #D0E1DD', background: THEME_COLORS.background, p: 3}}>
            <Grid container direction='row' justifyContent='space-between'>
                <Box>
                    <Grid container direction='column' alignItems='start'>
                        <Typography variant='subtitle2'
                                    sx={{color: THEME_COLORS.contentLeading}}>{application.first_name} {application.last_name}</Typography>
                        {applicationCompensationRange ?
                            <Typography variant='body2'>{applicationCompensationRange}</Typography> : <></>}
                    </Grid>
                </Box>
                <Grid>
                    <Grid container direction='row'>
                        <Grid my='auto'>
                            <Typography variant='body3' sx={{color: THEME_COLORS.surfaceDivider, mr: 2}}>
                                {applicationCreateAt()}
                            </Typography>
                            {showStatus()}
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>

        </Grid>
    )
}