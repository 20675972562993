import React from "react"
import { Alert, AlertTitle, Box, Snackbar } from "@mui/material"
import {AlertMessageType, ALERT_DURATION, SUCCESS_CODE} from "../constants/common"

interface AlertMessageProps {
code: number;
message: string | string[];
setMessage: (message: AlertMessageType | null) => void
style?: React.CSSProperties;
}

const AlertMessage: React.FC<AlertMessageProps> = ({code, message, setMessage, style }) => {
    return (
        <Box>      
            <Snackbar
                anchorOrigin={{ horizontal:"center", vertical:"top" }}
                open={!!message}
                autoHideDuration={ALERT_DURATION}
                onClose={() => setMessage(null)}
            >
                <Alert
                    style={{margin: "auto", ...style}}
                    variant="outlined"
                    severity={code === SUCCESS_CODE ? "success" : "error"}
                >
                    <AlertTitle>{code === SUCCESS_CODE ? "Success" : "Error"}</AlertTitle>
                    {Array.isArray(message) ? message[0].split(': ').at(-1) : message}
                </Alert>
            </Snackbar>
        </Box>
    )
}
    
export default AlertMessage