import React from "react";
import {createRoot} from "react-dom/client";
import "./index.css";
import App from "./App";
import {CssBaseline, ThemeProvider} from "@mui/material";
import {appTheme} from "./theme/theme";
import {Provider} from "react-redux";
import store, {persistor} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";


const rootElement = document.getElementById("root");
if (!rootElement) throw new Error('Failed to find the root element');
const root = createRoot(rootElement);
root.render(
    <Provider store={store}>
        <PersistGate persistor={persistor} loading={null}>
            <ThemeProvider theme={appTheme}>
                    <CssBaseline/>
                    <App/>
            </ThemeProvider>
        </PersistGate>
   </Provider>
);
