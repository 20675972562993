import {
    GET_BOUNTIES_APPLICATIONS_REQUEST,
    GET_BOUNTIES_APPLICATIONS_SUCCESS,
    GET_BOUNTIES_APPLICATIONS_FAILURE,
    GET_NEXT_PAGE_BOUNTIES_APPLICATIONS_REQUEST,
} from "../const"
import { BountiesApplicationsState, BountiesApplicationsAction } from "../types"


const initialState: BountiesApplicationsState = {
    bounties: [],
    total: 0,
    next: null,
    previous: null,
    tableLoading: false,
}
  
const handlers: any = {
    [GET_BOUNTIES_APPLICATIONS_REQUEST]: (state: BountiesApplicationsState) => ({...state, tableLoading: true, bounties: [], total: 0, previous: null, next: null}),
    [GET_BOUNTIES_APPLICATIONS_SUCCESS]: (state: BountiesApplicationsState, action: BountiesApplicationsAction) => ({
        ...state,
        bounties: [...state.bounties, ...action.payload.results],
        total: action.payload.count,
        tableLoading: false,
        next: action.payload.next,
        previous: action.payload.previous,
    }),
    [GET_BOUNTIES_APPLICATIONS_FAILURE]: (state: BountiesApplicationsAction) => ({...state, tableLoading: false}),
    [GET_NEXT_PAGE_BOUNTIES_APPLICATIONS_REQUEST]: (state: BountiesApplicationsAction) => ({...state}),
    
    DEFAULT: (state: BountiesApplicationsAction) => state,
}

const bountiesApplicationsReducer = (state: BountiesApplicationsState = initialState, action: BountiesApplicationsAction): BountiesApplicationsState => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
  };


export default bountiesApplicationsReducer
