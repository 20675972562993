export const UserRole = {
    RECRUITER: "Recruiter",
    EMPLOYER: "Employer"
}


export const BountyStatus = {
    DRAFT: "draft",
    ACTIVE: "active",
    FULFILLED: "fulfilled",
    CLOSED: "closed"
}


export const ApplicationStatus = {
    RECRUITER_REVIEWING: "Recruiter reviewing",
    RECRUITER_REJECTED: "Recruiter rejected",
    COMPANY_REVIEWING: "Company reviewing",
    COMPANY_REJECTED: "Company rejected",
    HIRED: "Hired"
}


export const SystemRole = {
    SYSTEM: "System",
    COMPANY_EMPLOYER: "Company employer",
    RECRUITER: "Recruiter"
}