import {
    GET_APPLICATION_DETAILS_REQUEST,
    GET_APPLICATION_DETAILS_SUCCESS,
    GET_APPLICATION_DETAILS_FAILURE,
} from "../const"
import { ApplicationDetailsAction, ApplicationDetailsState } from "../types"


const initialState: ApplicationDetailsState = {
    application: null,
    loading: false,
}
  
const handlers: any = {
    [GET_APPLICATION_DETAILS_REQUEST]: (state: ApplicationDetailsState) => ({...state, loading: true}),
    [GET_APPLICATION_DETAILS_SUCCESS]: (state: ApplicationDetailsState, action: ApplicationDetailsAction) => ({
        ...state,
        application: action.payload,
        loading: false,
    }),
    [GET_APPLICATION_DETAILS_FAILURE]: (state: ApplicationDetailsAction) => ({...state, loading: false}),
    
    DEFAULT: (state: ApplicationDetailsAction) => state,
}

const applicationDetailsReducer = (state: ApplicationDetailsState = initialState, action: ApplicationDetailsAction): ApplicationDetailsState => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
  };


export default applicationDetailsReducer
