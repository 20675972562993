import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {BountyResponse} from "../../interfaces/responses/Bounty";
import {ApplicationFormResponse} from "../../interfaces/responses/ApplicationForm";
import { UserResponse } from "interfaces/responses/Account";

type State = {
    id?: number;
    recruiter?: UserResponse;
    bounty?: BountyResponse;
    token?: string;
};

const initialState: State = { id: undefined, recruiter: undefined, bounty: undefined, token: undefined };

const applicationFormSlice = createSlice({
  name: "applicationForm",
  initialState,
  reducers: {
    setApplicationForm(
      state: State,
      action: PayloadAction<ApplicationFormResponse>
    ) {
      state.id = action.payload.id;
      state.recruiter = action.payload.recruiter;
      state.bounty = action.payload.bounty;
      state.token = action.payload.token;
    },
  },
});

export default applicationFormSlice;