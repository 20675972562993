import React from "react";
import {Avatar, Typography} from "@mui/material";
import {CompanyResponse} from "../interfaces/responses/Company";
import {THEME_COLORS} from "../constants/colors";


interface CompanyLogoProps {
    company?: CompanyResponse
}

function CompanyLogo({company}: CompanyLogoProps) {

    return (
        <Avatar sx={{width: 110, height: 110, background: "linear-gradient(to bottom right, #8fc6b3, #d0e4de)", borderRadius: '8px'}} variant="square">
            <Typography sx={{fontSize: 70, fontWeight: 600}}
                        color={THEME_COLORS.background}>{company?.legal_name[0]}</Typography>
        </Avatar>
    );
}

export default CompanyLogo;