import { BountiesResponse } from "../../../interfaces/responses/Bounty"
import {
    GET_BOUNTIES_REQUEST,
    GET_BOUNTIES_SUCCESS,
    GET_BOUNTIES_FAILURE,
    GET_NEXT_PAGE_BOUNTIES_REQUEST,
} from "../const"
import { BountiesAction } from "../types"
import axiosService from "../../../requests/axios"
import { API_URL } from "../../../constants/common"
import { Dispatch } from "@reduxjs/toolkit"


const request = (): BountiesAction => ({type: GET_BOUNTIES_REQUEST})

const nextPageRequest = () => ({type: GET_NEXT_PAGE_BOUNTIES_REQUEST})

const success = (bounties: BountiesResponse): BountiesAction => ({
    type: GET_BOUNTIES_SUCCESS,
    payload: bounties,
})

const failure = (): BountiesAction => ({type: GET_BOUNTIES_FAILURE})


export const receiveBounties = () => async (dispatch: Dispatch<BountiesAction>) => {
    dispatch(request())
    try {
        const {data: receiveBountiesData} = await axiosService.get(`${API_URL}/bounties/`)
        dispatch(success(receiveBountiesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}

export const receiveNextPageBounties = (url: string) => async (dispatch: Dispatch<BountiesAction>) => {
    dispatch(nextPageRequest())
    try {
        const {data: receiveNextPageBountiesData} = await axiosService.get(url)
        dispatch(success(receiveNextPageBountiesData))
    } catch (e) {
        console.log(e)
        dispatch(failure())
    }
}
